import {
  OptimizelyWrapper,
  UserConfigMainWrapper,
} from "@bees-grow-shared/business-components";
import { ThemeProvider } from "@bees-grow-shared/theme";

import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";
import { IntlProvider } from "./i18n";
import Router from "./Router";
import SideMenuWrapper from "./wrappers/sideMenuWrapper/SideMenuWrapper";

import "./global.css";

export default function App(props: Readonly<EnvConfig>) {
  return (
    <ThemeProvider>
      <IntlProvider>
        <SideMenuWrapper>
          <UserConfigMainWrapper>
            <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
              <EnvProvider env={props}>
                <Router />
              </EnvProvider>
            </OptimizelyWrapper>
          </UserConfigMainWrapper>
        </SideMenuWrapper>
      </IntlProvider>
    </ThemeProvider>
  );
}
