import React from "react";

const SideBarClubBIcon: React.FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7529 3.33977C11.5246 2.88674 12.4754 2.88674 13.2471 3.33977L18.7529 6.57173C19.5246 7.02476 20 7.86198 20 8.76803V15.232C20 16.138 19.5246 16.9752 18.7529 17.4283L13.2471 20.6602C12.4754 21.1133 11.5246 21.1133 10.7529 20.6602L5.24714 17.4283C4.47541 16.9752 4 16.138 4 15.232V8.76803C4 7.86198 4.47541 7.02476 5.24715 6.57173L10.7529 3.33977ZM12.6533 4.38563C12.249 4.14833 11.751 4.14833 11.3467 4.38563L5.84102 7.61759C5.43678 7.85489 5.18776 8.29344 5.18776 8.76803V15.232C5.18776 15.7066 5.43678 16.1451 5.84102 16.3824L11.3467 19.6144C11.751 19.8517 12.249 19.8517 12.6533 19.6144L18.159 16.3824C18.5632 16.1451 18.8122 15.7066 18.8122 15.232V8.76803C18.8122 8.29344 18.5632 7.85489 18.159 7.61759L12.6533 4.38563Z"
      fill="#141414"
    />
    <path
      d="M13.7485 7.42059H10.837C10.6266 7.42059 10.4548 7.5996 10.4548 7.81791V9.55125H15.7882C15.7882 8.3855 14.8778 7.43369 13.7485 7.42059ZM14.2938 10.6166H10.6094V12.7472H16.8231C16.5783 11.5073 15.5177 10.6166 14.2938 10.6166ZM10.6094 13.8126H16.8231C16.5783 15.0525 15.5177 15.9432 14.2938 15.9432H11.0499C10.9468 15.9432 10.6824 15.9016 10.6094 15.823C10.6094 15.7173 10.6094 15.6787 10.6094 15.5739V13.8126Z"
      fill="#141414"
    />
    <path
      d="M7.74522 13.0094C6.97227 13.8345 6.98516 15.1487 7.7667 15.9652C8.54824 16.7773 9.81502 16.7904 10.6094 15.987V10.0317L7.74522 13.0094Z"
      fill="#141414"
    />
  </svg>
);

export default SideBarClubBIcon;
