import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  accountSharedService,
  agentCallSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { BarChart, Crosshair, Store2, Truck } from "@hexa-ui/icons";
import { useHubTheme, useSidebar } from "admin-portal-shared-services";

import SideBarClubBIcon from "../../assets/sideBar/SideBarClubBIcon";
import { BASE_PATH } from "../../config/constants";

export const homeIcon = (): JSX.Element => <BarChart />;
export const accountIcon = (): JSX.Element => <Store2 />;
export const tasksIcon = (): JSX.Element => <Crosshair />;
export const checkoutIcon = (): JSX.Element => <Truck />;
export const leverageIcon = (): JSX.Element => <SideBarClubBIcon />;

export const useSidebarService = (): void => {
  const { formatMessage } = useIntl();
  const agentCallService = agentCallSharedService();
  const accountService = accountSharedService();

  const { callId } = useSharedUnit(agentCallService);
  const { accountId, vendorId } = useSharedUnit(accountService);

  const criticalStoriesDone = !!callId && !!accountId && !!vendorId;

  const sidebarProps = useMemo(
    () => ({
      items: [
        {
          id: "1",
          title: formatMessage({ id: "SIDEBAR.CALLS_AND_KPIS" }),
          icon: homeIcon,
          path: `${BASE_PATH}/home`,
          isVisible: true,
        },
        {
          id: "2",
          title: formatMessage({ id: "SIDEBAR.POC_OVERVIEW" }),
          icon: accountIcon,
          path: `${BASE_PATH}/account`,
          isVisible: criticalStoriesDone,
        },
        {
          id: "3",
          title: formatMessage({ id: "SIDEBAR.TASKS" }),
          icon: tasksIcon,
          path: `${BASE_PATH}/missions`,
          isVisible: criticalStoriesDone,
        },
        {
          id: "4",
          title: formatMessage({ id: "SIDEBAR.CHECKOUT" }),
          icon: checkoutIcon,
          path: `${BASE_PATH}/checkout`,
          isVisible: criticalStoriesDone,
        },
        {
          id: "5",
          title: formatMessage({ id: "SIDEBAR.CLUB_B" }),
          icon: leverageIcon,
          path: `${BASE_PATH}/leverage`,
          isVisible: criticalStoriesDone,
        },
      ].filter((item) => item.isVisible),
      utils: [],
    }),

    [criticalStoriesDone, formatMessage],
  );

  useHubTheme("grow");
  useSidebar(sidebarProps);
};
