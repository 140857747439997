/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense } from "react";

import "./global.css";
import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";

const loadable = <T extends React.ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
) => lazy(importFunc);

const LastOrdersTrayWrapper = loadable(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.LastOrdersTrayWrapper,
  })),
);
const OptimizelyWrapper = loadable(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.OptimizelyWrapper,
  })),
);
const AnalyticsWrapper = loadable(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.AnalyticsWrapper,
  })),
);
const ToastProvider = loadable(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.ToastProvider,
  })),
);
const UserConfigMainWrapper = loadable(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.UserConfigMainWrapper,
  })),
);
const ThemeProvider = loadable(() =>
  import("@bees-grow-shared/theme").then((module) => ({
    default: module.ThemeProvider,
  })),
);

const IntlProvider = loadable(() =>
  import("./i18n").then((module) => ({ default: module.IntlProvider })),
);
const Router = loadable(() => import("./Router"));
const CartWrapper = loadable(
  () => import("./wrappers/cartWrapper/CartWrapper"),
);
const SideMenuWrapper = loadable(
  () => import("./wrappers/sideMenuWrapper/SideMenuWrapper"),
);

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <Suspense fallback={<div></div>}>
        <ThemeProvider>
          <IntlProvider>
            <ToastProvider>
              <SideMenuWrapper>
                <UserConfigMainWrapper>
                  <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
                    <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
                      <LastOrdersTrayWrapper>
                        <CartWrapper>
                          <Router />
                        </CartWrapper>
                      </LastOrdersTrayWrapper>
                    </AnalyticsWrapper>
                  </OptimizelyWrapper>
                </UserConfigMainWrapper>
              </SideMenuWrapper>
            </ToastProvider>
          </IntlProvider>
        </ThemeProvider>
      </Suspense>
    </EnvProvider>
  );
}
