import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  test: {
    translate: "Translate",
    missing: "Only English option available",
  },
  SIDEBAR: {
    CALLS_AND_KPIS: "Calls and KPIs",
    POC_OVERVIEW: "POC overview",
    TASKS: "Tasks",
    CHECKOUT: "Checkout",
    CLUB_B: "Club B",
  },
};

export default enUS;
