import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { BASE_PATH } from "./config/constants";

const Router = (): JSX.Element => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const redirectToHome = <Navigate to={`${BASE_PATH}/home`} />;

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={BASE_PATH} element={redirectToHome} />

        <Route path={`${BASE_PATH}/home`} element={null} />

        {isFirstRender && <Route path="*" element={redirectToHome} />}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
