import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  SIDEBAR: {
    CALLS_AND_KPIS: "Chamadas e KPIs",
    POC_OVERVIEW: "Visão geral da POC",
    TASKS: "Tarefas",
    CHECKOUT: "Checkout",
    CLUB_B: "Clube B",
  },
};

export default ptBR;
