import MessageMap from "../i18n.d";

const es419: MessageMap = {
  SIDEBAR: {
    CALLS_AND_KPIS: "Llamadas y KPIs",
    POC_OVERVIEW: "Descripción general de POC",
    TASKS: "Tareas",
    CHECKOUT: "Checkout",
    CLUB_B: "Club B",
  },
};

export default es419;
