// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#grow-container {
  flex: 1;
  background: transparent;
  padding-top: 1.5rem;
  padding-left: 7rem;
  padding-right: 1.5rem;
  min-width: 1024px;
  max-width: 1920px;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["#grow-container {\n  flex: 1;\n  background: transparent;\n  padding-top: 1.5rem;\n  padding-left: 7rem;\n  padding-right: 1.5rem;\n  min-width: 1024px;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
