import { useSidebarService } from "@/hooks/useSidebarService/useSidebarService";

export interface SideMenuWrapperProps {
  children: React.ReactNode;
}
export default function SideMenuWrapper({ children }: SideMenuWrapperProps) {
  useSidebarService();

  return <>{children}</>;
}
